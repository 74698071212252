<template>
  <div>

    <!-- 조각형 아이템 -->
    <div v-if="scope === 'item'" pdb="lg">
      <div class="gridbox" :scope="resolution.width > resolution.mobile ? `` : `mobile`">
        <div v-for="n in resolution.width > resolution.mobile ? 12 : 2" class="gridbox-element" :pdb="resolution.width > resolution.mobile ? `` : ``" style="background:none;" :key="n">
          <div class="box" direction="column" gap="sm">
            <div class="box" :bg="bg" radius="3xs" :pd="resolution.width > resolution.mobile ? `6xl-y` : `5xl-y`" halign="center" fsize="sm">
              <v-progress-circular indeterminate width="1" color="primary" :size="resolution.width > resolution.mobile ? `30` : `20`" />
            </div>
            <div class="box" gap="md">
              <div :bg="bg" radius="3xs" style="width:59px;height:59px;"></div>
              <div flex="1" class="box" gap="xs" direction="column">
                <div :bg="bg" radius="3xs" style="width:85%;height:23px;"></div>
                <div :bg="bg" radius="3xs" style="width:75%;height:23px;"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 긴 박스 -->
    <div v-else-if="scope === 'widebox'" :pd="resolution.width > resolution.notebook ? `md-x` : `2xl-y`" :mg="resolution.width > resolution.notebook ? `md` : `md-x`">
      <div class="box" direction="column" :gap="resolution.width > resolution.notebook ? `3xl` : `md`">
        <div v-for="n in 2" class="box" gap="md" :key="n">
          <div class="box" :bg="bg" radius="3xs" valign="center" halign="center" :style="`width:${resolution.width > resolution.mobile ? `180px` : `116px`};height:96px;`">
            <v-progress-circular indeterminate color="primary" size="25" width="1" />
          </div>
          <div flex="1" class="box" gap="2xs" direction="column">
            <div :bg="bg" radius="3xs" style="width:75%;height:30px;"></div>
            <div :bg="bg" radius="3xs" style="width:85%;height:30px;"></div>
            <div :bg="bg" radius="3xs" style="width:300px;max-width:50%;height:20px;"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 나의 통계 -->
    <div v-else-if="scope === 'stastics'">
      <div class="box" direction="column" :gap="`md`" pdb="lg">
        <div class="box" valign="center">
          <div :bg="bg" radius="3xs" style="width:150px;height:36px;"></div>
        </div>
        <v-divider />
        <div v-for="n in 2" class="box" gap="md" :key="n">
          <div class="box" :bg="bg" radius="3xs" valign="center" halign="center" :style="`width:${resolution.width > resolution.mobile ? `180px` : `116px`};height:85px;`">
            <v-progress-circular indeterminate color="primary" size="25" width="1" />
          </div>
          <div flex="1" class="box" gap="2xs" direction="column">
            <div :bg="bg" radius="3xs" style="width:75%;height:25px;"></div>
            <div :bg="bg" radius="3xs" style="width:85%;height:25px;"></div>
            <div :bg="bg" radius="3xs" style="width:300px;max-width:50%;height:20px;"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 게시판 -->
    <div v-else-if="scope === 'board'">
      <div class="box" direction="column" :gap="resolution.width > resolution.notebook ? `xs` : `md`">
        <div class="box" valign="center">
          <div :bg="bg" radius="3xs" style="width:200px;height:36px;"></div>
        </div>
        <v-divider mg="2xs-y" />
        <div v-for="n in 2" class="box" gap="md" :key="n">
          <div class="box" :bg="bg" radius="3xs" valign="center" halign="center" style="width:100%;height:70px;">
            <v-progress-circular indeterminate color="primary" size="25" width="1" />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '~/store/global'

export default {
  setup () {

    // 설정
    const loaded: any = ref(false)
    const { resolution } = storeToRefs(useGlobalStore())

    // 메모리 비우기
    function restore () {
      loaded.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    // 서버, 클라이언트 체크
    onBeforeMount (() => {
      loaded.value = true
    })

    return {
      loaded,
      resolution
    }
  },
  props: {
    scope: {
      type: String,
      default () {
        return 'login'
      }
    },
    bg: {
      type: String,
      default () {
        return '5xs'
      }
    }
  }
}
</script>